import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Services from "../components/Services"

import Projects from "../components/Projects"
import SEO from "../components/SEO"
import Scroll from "../components/Scroll"


export default ({ data }) => {
  const {
    allStrapiProjects: { nodes: project },
  } = data
  return (
    <Layout>
      <Scroll showBelow={250}/>
      <SEO title="Home" />
      <Hero />
      <Services />  
      <Projects projects={project} title="Proyectos destacados" showLink />
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiProjects(filter: { featured: { eq: true } }) {
      nodes {
        github
        id
        description
        title
        url
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        stack {
          id
          title
        }
      }
    }
  }
`
