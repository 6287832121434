import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import { FaAngleDoubleDown } from "react-icons/fa"
import SocialLinks from "../constants/socialLinks"
import scrollTo from 'gatsby-plugin-smoothscroll';
const query = graphql`
  {
    file(relativePath: { eq: "Logos_Neurona_Servicios-13.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const Hero = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)

  return (
    <header className="hero">
      <div className="section-center hero-center">
        <article className="hero-info">
          <div>
            <div className="underline"></div>
            <h1>Neurona</h1>
            <h4>Pensamos soluciones </h4>
            <Link to="/contact" className="btn">
              contáctanos
            </Link>
            <SocialLinks />
          </div>
        </article>
        <Image fluid={fluid} className="hero-img hero-logo-spin" />
      </div>
     
      <button onClick={() => scrollTo('#servicios')}>
     <div className="scrolldown">
      <span></span>
      <span></span>
      <span></span>

      </div>
      </button>
      
      
    </header>
  )
}

export default Hero
