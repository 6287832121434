import React from "react"
import { FaCode, FaSketch, FaAndroid, FaLaptop,FaBolt,FaCloud ,FaHandshake} from "react-icons/fa"
export default [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "Desarrollo web",
    text: `Desarrollo, rediseño y mantenimiento de landing pages, tiendas online, páginas corporativas, páginas de hotelería, portafolios, menús digitales, etc. Nos ajustamos a las necesidades del proyecto.`,
  },
  {
    id: 2,
    icon: <FaLaptop className="service-icon" />,
    title: "Software a la medida",
    text: `Diseño de programas personalizados para personas y/o organizaciones que no encuentren programas existentes en el mercado que se ajusten a sus demandas.`,
  },
  {
    id: 3,
    icon: <FaBolt className="service-icon" />,
    title: "Electricidad y electrónica",
    text: `Creación, diseño e implementación de proyectos electrónicos y diseño de planos eléctricos e instalaciones eléctricas.`,
  },
  {
    id: 4,
    icon: <FaAndroid className="service-icon" />,
    title: "Desarrollo de aplicaciones",
    text: `Diseño de aplicaciones web y móviles, desde la maquetación y creación del diseño UI/UX, hasta su implementación, soporte y futuras actualizaciones.`,
  },
  {
    id: 5,
    icon: <FaCloud className="service-icon" />,
    title: "IoT",
    text: `Creación de dispositivos, aplicaciones y/o sensores conectados a Internet permitiendo la automatización de procesos tecnológicos en la vida cotidiana de las personas u organizaciones.`,
  },
  {
    id: 6,
    icon: <FaHandshake className="service-icon" />,
    title: "Asesorías",
    text: `Si consideras que tus ideas o proyectos no están relacionados con los servicios anteriormente mencionados, puedes ponerte en contacto con nosotros y te ayudaremos para llevarlos a cabo.`,
  },
  
]
